function Services() {
  return (
    <div>
      <h1>Services</h1>

      <h2>Web Development</h2>
      <p>I specialise in bespoke, technical web applications, using modern development tools such as Vue, React, Angular and Laravel.</p>
      <p>Whilst I favour back-end and API development, I'm a full-stack developer.</p>

      <h2>App Development</h2>
      <p>I've released multiple mobile and tablet apps onto the Apple App Store and Google Play Stores, ranging from simple timewasters, to full businesses.</p>

      <h2>Games & Interactives</h2>
      <p>I have a background in game and interactive development, with games published on the app stores.</p>
      <p>These are some of my favourite projects to work on :)</p>

      <h2>Internet of Things</h2>
      <p>I've worked on several projects enabling the data capture and processing of custom devices, such as smart water meters and air quality measurement devices.</p>

      <h2>Technical Consulting</h2>
      <p>I've helped numerous projects get off the ground with advice on technical details.  I've also helped small startups successfully raise investor funding, to develop their products further.</p>

    </div>
  );
}

export default Services;

import '../../styles/work.css';
import Icon from '../../components/common/Icon';
import iosIcon from '../../img/iosIcon.svg';
import androidIcon from '../../img/androidIcon.svg';
import webIcon from '../../img/webIcon.svg';
import iotIcon from '../../img/iotIcon.svg';
import Config from '../../config.json';

function Work() {
  const projects = Config.projects.sort(() => .5 - Math.random());
  return (
    <div>
      <h1>Work</h1>

      <h2>Over the years, I've made <em>a lot</em> of things :)</h2>
      <p>Here's some of the many projects I've been involved in...</p>

      <ul className="projects">
        { 
         projects.map((project) => (
            <li key={ project.title }>
              { project.title }
              { project.platforms.indexOf('ios') !== -1 ? <Icon title="iPhone/iPad" icon={iosIcon} /> : '' }
              { project.platforms.indexOf('android') !== -1 ? <Icon title="Android" icon={androidIcon} /> : '' }
              { project.platforms.indexOf('www') !== -1 ? <Icon title="Website" icon={webIcon} /> : '' }
              { project.platforms.indexOf('iot') !== -1 ? <Icon title="Internet of Things" icon={iotIcon} /> : '' }
            </li>
          ))
        }
      </ul>

    </div>
  );
}

export default Work;

import logo from '../../img/prelethal.webp';
import NavLink from './NavLink';
import { Pages } from '../pages/pages';
import '../../styles/nav.css';

function Nav() {
  return (
    <nav className="nav-container">
      <ul>
        <NavLink link={Pages.About} />
        <NavLink link={Pages.Services} />
        <NavLink link={Pages.Work} />
        <NavLink link={Pages.Contact} />
      </ul>
      <img src={logo} alt="Pete Hall - Software Engineer for hire" />
    </nav>
  );
}

export default Nav;

import ContactDetails from '../common/ContactDetails';

function Contact() {
  return (
    <div>
      <h1>Contact Me</h1>

      <h2>Get in touch via Linkedin or email to see how we can work together</h2>
      
      <p>
      <ContactDetails showLinkedin={true} />
      </p>

    </div>
  );
}

export default Contact;

import { useDispatch } from "react-redux";
import { pageChanged } from '../../redux/page';
import { useSelector } from 'react-redux';

function NavLink(props) {
  const dispatch = useDispatch();
  const { currPage } = useSelector((state) => state.page);

  return (
    <li className={ currPage === props.link.key ? 'active' : '' }><button onClick={() => dispatch(pageChanged(props.link.key))}>{props.link.title}</button></li>
  );
}

export default NavLink;

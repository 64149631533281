import Nav from './components/nav/Nav';
import PageComponent from './components/pages/pages';
import { useSelector } from 'react-redux';

function App() {
  const { currPage } = useSelector((state) => state.page);

  return (
    <div className="main-container">
      <Nav />
      <div className="main-content">
        <PageComponent page={currPage} />
      </div>
    </div>
  );
}

export default App;

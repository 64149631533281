import About from './About';
import Services from './Services';
import Work from './Work';
import Contact from './Contact';

export const Pages = {
    About: {
        title: 'About',
        key: 'About',
        module: About
    },
    Services: {
        title: 'Services',
        key: 'Services',
        module: Services
    },
    Work: {
        title: 'Work',
        key: 'Work',
        module: Work
    },
    Contact: {
        title: 'Contact',
        key: 'Contact',
        module: Contact
    }
};

function PageComponent(props) {
    const PageData = Pages[props.page].module;
    return <PageData />;
}
  
export default PageComponent;

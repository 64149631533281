import { createSlice } from '@reduxjs/toolkit'
import { Pages } from '../components/pages/pages';

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    currPage: Object.keys(Pages)[0]
  },
  reducers: {
    pageChanged: (state, actions) => {
      state.currPage = actions.payload;
    }
  }
})

export const { pageChanged } = pageSlice.actions;
export default pageSlice.reducer;

import Config from '../../config.json';

function ContactDetails(props) {
  return (
    <>
      {props.showLinkedin ? <a href={Config.contact.linkedin} target="_blank" rel="noreferrer">Linkedin Profile</a> : '' }
      {props.showLinkedin ? <br /> : '' }
      <a href={'mailto:' + Config.contact.email} target="_blank" rel="noreferrer">{Config.contact.email}</a><br />
      <a href={'mailto:' + Config.contact.email_2} target="_blank" rel="noreferrer">{Config.contact.email_2}</a>
    </>
  );
}

export default ContactDetails;

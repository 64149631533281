import ContactDetails from "../common/ContactDetails";

function About() {
  return (
    <div>
      <h1>I'm Pete Hall</h1>

      <h2><em>"Software engineer extraordinaire"</em></h2>

      <p>With 18 years’ experience building and managing digital products, for clients such as Network Rail, O2, Sony, NHS, Vodaphone and at least one of your favourite bands.</p>
      <p>I've worked with dozens of global clients and top digital agencies over the years, happily fitting into existing development teams, taking full lead on projects or providing technical consultation.</p>
      <p>I'm currently the technical lead at <a href="https://makemachine.co.uk" target="_blank" rel="noreferrer">Make Machine</a>, specialising in delivering mobile apps, games and complex web applications.</p>

      <p>Let's talk:<br />
        <ContactDetails showLinkedin={false} />
      </p>
    </div>
  );
}

export default About;
